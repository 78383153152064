import React from "react";

function SpotlightPoints() {
    let icon = 'fa-sharp fa-solid fa-hand';
    return (
        <div className="spotlight">
            <div className={'spotlight-item'}>
                <i className="spotlight-icon fa-sharp fa-solid fa-hand"></i>
                <p>Goodbye to inaccurate estimating</p>
            </div>
            <div className={'spotlight-item'}>
                <i className="spotlight-icon fa-sharp fa-solid fa-stopwatch"></i>
                <p>Save time and provide reliable insights</p>
            </div>

            <div className={'spotlight-item'}>
                <i className="spotlight-icon fa-sharp fa-solid fa-shuffle"></i>
                <p>Informed decisions keep projects on track</p>
            </div>

            <div className={'spotlight-item'}>
                <i className="spotlight-icon fa-sharp fa-solid fa-heart-pulse"></i>
                <p>Reduce stress and uncertainty of project management</p>
            </div>

            <div className={'spotlight-item'}>
                <i className="spotlight-icon fa-sharp fa-solid fa-arrows-to-dot"></i>
                <p>Focus on what matters most</p>
            </div>

            <div className={'spotlight-item'}>
                <i className="spotlight-icon fa-sharp fa-solid fa-people-group"></i>
                <p>Greater success for you and your team</p>
            </div>

        </div>
    )
}

export default SpotlightPoints;