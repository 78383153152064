import React, { useState } from "react";

function Content() {

    return (
        <div>
            <p>Are you tired of constantly guessing how long your projects will take? Do you find that your team's estimations are often inaccurate and lead to missed deadlines? Our product is here to help.</p>

            <p>We use cutting-edge analytics to provide accurate project forecasting that can help you plan more effectively and meet your goals with ease. With our analytics-based forecasting, you can rely on data-driven insights to make informed decisions and adjust your plans as needed.</p>

            <p>Our product provides a powerful alternative to traditional story point estimation, which can be time-consuming, subjective, and ultimately unreliable. Instead, our analytics-based approach takes into account a wide range of factors to provide a more accurate picture of your project's timeline and potential obstacles.</p>

            <p>By switching to our product, you can reduce the stress and uncertainty of project management and achieve greater success with your team. Say goodbye to missed deadlines and frustrating setbacks, and hello to streamlined project planning and execution.</p>

            <p>Don't settle for guesswork and estimation-based planning. Upgrade to our product today and experience the power of analytics-based forecasting for yourself.</p>
        </div>
    )
}

export default Content;