import React from "react";

function JoinWaitList() {
    return (
        <div className={"waitlist"}>
            <div id="omnisend-embedded-v2-642659aff80280ce4e4ebba5"></div>
        </div>
    )
}

export default JoinWaitList;