import logo from './logo.svg';
import './App.css';
import Content from "./components/Content";
import SpotlightPoints from "./components/SpotlightPoints";
import JoinWaitList from "./components/JoinWaitList";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
      <header className="App-header">
          <i className="logo fa-sharp fa-solid fa-dharmachakra fa-2xl"></i>
          <a
              className="App-link"
              href="/"
              rel="noopener noreferrer"
          >
              GitSpokes
          </a>
      </header>
        <div className={"subHeader"}>
            <p className={'subtitle'}>Navigate your projects with precision using our data-driven insights</p>
        </div>
      <content>
          <section>
              <h1>GitSpokes - A Better Way</h1>
              <SpotlightPoints />
          </section>
          <section>
              <JoinWaitList />
          </section>
          <section>
              <h2>Still Not Sure?</h2>
              <Content />
          </section>
      </content>
        <Footer />
    </div>
  );
}

export default App;
